import React, { useEffect } from "react";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import Partners from "@/components/Home/Partners";
import Breadcrumb from "@/components/Common/Breadcrumb";
import SinglePost from "@/components/News/SinglePost";
import { useLocation } from "@reach/router";
import BlogLayout from "@/components/Layout/BlogLayout";
import { useBlog } from "@/context/BlogContext";

const SinglePostBySlug = ({ params }: any) => {
  const { slug } = params;
  const { state, fetchPBySlug, clearStorage } = useBlog();

  useEffect(() => {
    ["search", "category", "author", "tag"].forEach((el) => {
      clearStorage(el);
    });
    fetchPBySlug(slug);
  }, [slug]);

  return (
    <Layout>
      <SEO title="Post Details" canonical={`${useLocation().host}`} />
      <Breadcrumb name="Post Details" pathName="Post Details" />
      <BlogLayout>
        {state.isLoading ||
        state.currentPost === null ||
        state.currentPost === undefined ? (
          <h2>Loading...</h2>
        ) : (
          <SinglePost post={state.currentPost} />
        )}
      </BlogLayout>
      <Partners />
    </Layout>
  );
};

export default SinglePostBySlug;
