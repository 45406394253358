/* eslint-disable @typescript-eslint/no-floating-promises */
import React from "react";
import { format } from "date-fns";
import { isBrowser } from "@/components/Header";
import { parseDate } from "@/components/Careers/Jobs";
import { type PostListItem } from "@/types";
import { navigate } from "gatsby";

interface PostProps {
  post: PostListItem;
}

const SinglePost: React.FC<PostProps> = ({ post }) => {
  const currentUrl = isBrowser && window.location.href;

  return (
    <div className="blog-single-post-listing details mb--0 mr--0">
      <div className="thumbnail">
        <img src={post.imageUrl} alt="Single Post Image" />
      </div>
      <div className="blog-listing-content">
        <div className="user-info">
          <div className="single author-w">
            <i className="far fa-user-circle"></i>
            <span
              onClick={() => {
                navigate(`/news/author/${post.author}`);
              }}
            >
              {`by ${String(post.author)}`}
            </span>
          </div>
          <div className="single">
            <i className="far fa-clock"></i>
            <span>
              {post.createdAt !== ""
                ? format(parseDate(post.createdAt), "d MMMM, yyyy")
                : ""}
            </span>
          </div>
          {post.tags.length > 0 && (
            <div className="single tag-w">
              <i className="far fa-tags"></i>
              <span>
                {post.tags?.map((tag) => (
                  <span
                    className="mr--20"
                    key={tag.id}
                    onClick={() => {
                      navigate(`/news/tag/${tag.name}`);
                    }}
                  >
                    {tag.name}
                  </span>
                ))}
              </span>
            </div>
          )}
        </div>
        <h3 className="title">{post.title}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: post.content ?? "",
          }}
        ></p>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="details-tag">
              <h6>Categories:</h6>
              {post.categories?.map((category) => (
                <span
                  key={category.id}
                  className="button"
                  onClick={() => {
                    navigate(`/news/category/${category.id}`);
                  }}
                >
                  {category.name}
                </span>
              ))}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="details-share">
              <h6>Share:</h6>
              <button>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${String(
                    currentUrl,
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </button>
              <button>
                <a
                  href={`https://twitter.com/intent/tweet?url=${String(
                    currentUrl,
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>{" "}
                </a>
              </button>
              {/* <button>
                            <i className="fab fa-instagram"></i>
                          </button> */}
              <button>
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${String(
                    currentUrl,
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in"></i>{" "}
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;
